import React from "react";
import { Screen } from "./subcomponents/Screen";

const ScreenPage = () => {
  const [activeQueue, setActiveQueue] = React.useState(null);
  const [selectedPick, setSelectedPick] = React.useState(null);
  const [selectedDispatch, setSelectedDispatch] = React.useState(null);
  const [hoveredDelivery, setHoveredDelivery] = React.useState(null);
  const [drivers, setDrivers] = React.useState([]);
  const [machines, setMachines] = React.useState([]);
  const [toPick, setToPick] = React.useState([]);
  const [toDispatch, setToDispatch] = React.useState([]);

  const PICK = "pick";
  const DISPATCH = "dispatch";

  const findDeliveryIndexInQueue = (queue, id) => {
    if (queue === PICK) {
      return toPick.findIndex((delivery) => delivery.data.id === id);
    } else {
      return toDispatch.findIndex((delivery) => delivery.data.id === id);
    }
  };

  const selectDelivery = (delivery, method = "key") => {
    const pickResult = findDeliveryIndexInQueue(PICK, delivery.data.id);
    const dispatchResult = findDeliveryIndexInQueue(DISPATCH, delivery.data.id);

    console.log(delivery.data.id);
    console.log("Pick Result", pickResult);
    console.log("Dispatch Result", dispatchResult);

    if (pickResult > -1) {
      setSelectedPick({ delivery: toPick[pickResult], selectedBy: method });
      setActiveQueue(PICK);
    } else if (dispatchResult > -1) {
      setSelectedDispatch({
        delivery: toDispatch[dispatchResult],
        selectedBy: method,
      });
      setActiveQueue(DISPATCH);
    }
  };

  const resetSelections = () => {
    setSelectedPick(null);
    setSelectedDispatch(null);
    setActiveQueue(null);
  };

  const activeQueueItems = () => (activeQueue === PICK ? toPick : toDispatch);

  const activeDelivery = () =>
    activeQueue === PICK ? selectedPick : selectedDispatch;

  const handleKeyDown = () => {
    if (activeQueue && (selectedPick || selectedDispatch)) {
      const queueItems = activeQueueItems();
      const selectedDelivery = activeDelivery();

      console.log("Queue items", queueItems);

      console.log("Selected delivery", selectedDelivery);
      console.log(selectedDelivery.delivery.data.id);

      const selectedIndex = queueItems.indexOf(selectedDelivery.delivery);

      console.log("Selected index", selectedIndex);

      if (selectedIndex >= queueItems.length - 1) {
        const firstDelivery = queueItems[0];
        selectDelivery(firstDelivery);
      } else {
        const nextDelivery = queueItems[selectedIndex + 1];
        console.log("Next delivery", nextDelivery);
        selectDelivery(nextDelivery);
      }
    } else {
      console.log("Select first time");
      selectDelivery(toPick[0]);
    }
  };

  const selectNextInQueue = (queue) => {
    const queueItems = queue === PICK ? toPick : toDispatch;
    const selectedDelivery = queue === PICK ? selectedPick : selectedDispatch;
    const currentIndex = queueItems.indexOf(selectedDelivery.delivery);
    const nextIndex = currentIndex <= queueItems.length - 1 ? currentIndex : 0;
    const nextDelivery = queueItems[nextIndex];
    selectDelivery(nextDelivery);
  };

  const handleKeyUp = () => {
    if (activeQueue) {
      const queueItems = activeQueueItems();
      const selectedDelivery = activeDelivery();
      const selectedIndex = queueItems.indexOf(selectedDelivery.delivery);

      if (selectedIndex === 0) {
        const lastDelivery = queueItems[queueItems.length - 1];
        selectDelivery(lastDelivery);
      } else {
        const prevDelivery = queueItems[selectedIndex - 1];
        selectDelivery(prevDelivery);
      }
    } else {
      selectDelivery(toPick[0]);
    }
  };

  const handleKeyLeftRight = () => {
    if (activeQueue) {
      const newQueue = activeQueue === PICK ? DISPATCH : PICK;
      const newQueueItems = newQueue === PICK ? toPick : toDispatch;
      const newQueueSelection =
        newQueue === PICK ? selectedPick : selectedDispatch;
      if (!newQueueSelection) {
        const firstItem = newQueueItems[0];
        selectDelivery(firstItem);
      } else {
        setActiveQueue(newQueue);
      }
    } else {
      selectDelivery(toPick[0]);
    }
  };

  const handleEnter = (delivery) => {
    handleAdvance(delivery.delivery);
  };

  useKeyPress("ArrowDown", handleKeyDown);
  useKeyPress("ArrowUp", handleKeyUp);
  useKeyPress("ArrowLeft", handleKeyLeftRight);
  useKeyPress("ArrowRight", handleKeyLeftRight);
  useKeyPress("Enter", () => handleEnter(activeDelivery()));

  function useKeyPress(targetKey, onPress) {
    function downHandler({ key }) {
      if (key === targetKey) {
        onPress();
      }
    }

    // Add event listeners
    React.useEffect(() => {
      window.addEventListener("keydown", downHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }, [activeQueue, selectedPick, selectedDispatch, toPick, toDispatch]);
  }

  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("X-SECRET-PASSWORD", "Weldmet1983!");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("/warehouse/data", requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setDrivers(json.drivers);
        setMachines(json.machines);
        setToPick(json.deliveries.to_pick);
        setToDispatch(json.deliveries.to_dispatch);
      });
  };

  React.useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleAdvance = (selectedDelivery) => {
    const currentStage = toPick.some(
      (item) => item.data.id === selectedDelivery.data.id
    )
      ? PICK
      : DISPATCH;

    if (currentStage === PICK) {
      // Mark as picked
      const updatedPickItems = toPick.filter(
        (item) => item.data.id !== selectedDelivery.data.id
      );
      const updatedDispatchItems = [selectedDelivery, ...toDispatch];

      setToPick(updatedPickItems);
      setToDispatch(updatedDispatchItems);
      patchStatus(selectedDelivery.data.id, "picked");
      setActiveQueue(DISPATCH);
      setSelectedPick(null);
      setSelectedDispatch({
        delivery: selectedDelivery,
        selectedBy: "key",
      });
    } else {
      // Mark as dispatched (remove from screen)
      const updatedDispatchItems = toDispatch.filter(
        (item) => item.data.id !== selectedDelivery.data.id
      );
      setToDispatch(updatedDispatchItems);
      patchStatus(selectedDelivery.data.id, "dispatched");
      resetSelections();
    }
  };

  const handleSkip = (selectedDelivery) => {
    const updatedPickItems = toPick.filter(
      (item) => item.data.id !== selectedDelivery.data.id
    );
    setToPick(updatedPickItems);
    patchStatus(selectedDelivery.data.id, "dispatched");
    resetSelections();
  };

  const patchStatus = (deliveryId, status) => {
    var myHeaders = new Headers();
    myHeaders.append("X-SECRET-PASSWORD", "Weldmet1983!");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ status });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/warehouse/deliveries/${deliveryId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  return (
    <Screen
      drivers={drivers}
      machines={machines}
      toPick={toPick}
      toDispatch={toDispatch}
      onAdvance={handleAdvance}
      onSkip={handleSkip}
      onCancelSelection={() => {
        setHoveredDelivery(null);
        setSelectedPick(null);
        setSelectedDispatch(null);
      }}
      selectedDelivery={
        activeQueue && (activeQueue === PICK ? selectedPick : selectedDispatch)
      }
      hoveredDelivery={hoveredDelivery}
      onSelect={(delivery) => {
        const selectedDelivery = activeDelivery();
        if (
          activeQueue &&
          selectedDelivery &&
          selectedDelivery.delivery.data.id === delivery.data.id
        ) {
          setActiveQueue(null);
        } else {
          selectDelivery(delivery);
        }
      }}
      onHover={(delivery, queue) =>
        setHoveredDelivery({
          delivery: delivery,
          index: toPick.indexOf(delivery),
          queue,
        })
      }
      onHoverEnd={() => setHoveredDelivery(null)}
    />
  );
};

export default ScreenPage;
