import React from "react";

export const Badge = ({ children, colour }) => (
  <span
    className="ml-2 rounded text-sm text-white font-bold select-none uppercase whitespace-nowrap"
    style={{ padding: "3px 4px", fontSize: "11px", backgroundColor: colour }}
  >
    {children}
  </span>
);
