import React from "react";
import { Header } from "../Header";
import { DeliveryTray } from "../DeliveryTray";
import { DeliveryDetailTray } from "../DeliveryDetailTray";
import { DriverTray } from "../DriverTray";

export const Screen = ({
  selectedDelivery,
  hoveredDelivery,
  drivers,
  machines,
  toPick,
  toDispatch,
  onAdvance,
  onSkip,
  onCancelSelection,
  onSelect,
  onHover,
  onHoverEnd,
}) => (
  <div className="m-4 mb-0 flex flex-col items-stretch">
    <Header />
    {/* <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-4"> */}
    <div className="flex flex-col items-stretch justify-stretch h-full mt-4">
      {/* <DeliveryTray
        stage="pick"
        selectedDelivery={selectedDelivery}
        deliveries={toPick}
        onAdvance={onAdvance}
        onSkip={onSkip}
        onSelect={(delivery) => onSelect(delivery, "pick")}
        onHover={(delivery) => onHover(delivery, "pick")}
        onHoverEnd={onHoverEnd}
      />
      <DeliveryTray
        stage="dispatch"
        selectedDelivery={selectedDelivery}
        deliveries={toDispatch}
        onAdvance={onAdvance}
        onSkip={onSkip}
        onSelect={(delivery) => onSelect(delivery, "dispatch")}
        onHover={(delivery) => onHover(delivery, "dispatch")}
        onHoverEnd={onHoverEnd}
      /> */}
      {/* {selectedDelivery?.delivery || hoveredDelivery?.delivery ? (
        <DeliveryDetailTray
          delivery={selectedDelivery?.delivery || hoveredDelivery?.delivery}
          onCancel={onCancelSelection}
        />
      ) : ( */}
      <DriverTray drivers={drivers} machines={machines} />
      {/* )} */}
    </div>
  </div>
);
