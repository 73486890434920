import { Controller } from "stimulus";

export default class extends Controller {
  // Targets are the things we want to control from within the JS
  // They will be named with `data-target="..."` attributes in the HTML
  // Stimulus.js (the framework) will automatically create objects for each target
  // on the 'this' keyboard with an appended 'Target' to the name.
  static targets = ["container"]

  addItem() {
    const itemNumber = this.containerTarget.children.length + 1
    // Create a new item text field
    const newItem = document.createElement("input")
    // Add our styling
    newItem.className = "large-text-input mb-2"
    newItem.placeholder = `Item Code #${itemNumber}`
    // Make sure it does the same thing on key press as the first input field
    newItem.setAttribute("data-action", "keydown->weights--index#keyboardPress")
    // Give it the name, which means that it will be put in the same array
    // as the other item codes, when Rails processes the form payload.
    newItem.name = 'item_codes[]'
    // Append the new item to the end of the container
    this.containerTarget.appendChild(newItem)
    // Focus the keyboard on that text field
    newItem.focus()
  }

  // This is called every time a key is pressed on any of the textboxes
  // We check to see if it's the enter key (which is key code 13)
  // And if it is, we silent the default response (to submit the form)
  // And instead add an item, and focus that field.
  keyboardPress(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.addItem()
    }
  }
}
