import React from "react";
import { Delivery } from "./subcomponents/Delivery";
import { ModeToggle } from "./subcomponents/ModeToggle";

const sortModes = [
  { label: "New To Old", icon: "sort-numeric-down" },
  { label: "Old To New", icon: "sort-numeric-down-alt" },
  { label: "Alphabetical", icon: "sort-alpha-down" },
  { label: "Reverse Alphabetical", icon: "sort-alpha-down-alt" },
];

export const DeliveryTray = ({
  stage,
  selectedDelivery,
  deliveries,
  onAdvance,
  onSkip,
  onSelect,
  onHover,
  onHoverEnd,
}) => {
  const [sortMode, setSortMode] = React.useState(0);

  const toggleSortMode = () => {
    if (sortMode >= sortModes.length - 1) {
      setSortMode(0);
    } else {
      setSortMode(sortMode + 1);
    }
  };

  const oldToNew = (a, b) => {
    if (a.data.attributes.hansa_id < b.data.attributes.hansa_id) {
      return -1;
    } else if (a.data.attributes.hansa_id > b.data.attributes.hansa_id) {
      return 1;
    } else {
      return 0;
    }
  };

  const newToOld = (a, b) => {
    if (a.data.attributes.hansa_id > b.data.attributes.hansa_id) {
      return -1;
    } else if (a.data.attributes.hansa_id < b.data.attributes.hansa_id) {
      return 1;
    } else {
      return 0;
    }
  };

  const alphabetical = (a, b) => {
    if (a.data.attributes.address_0 < b.data.attributes.address_0) {
      return -1;
    } else if (a.data.attributes.address_0 > b.data.attributes.address_0) {
      return 1;
    } else {
      return 0;
    }
  };

  const reverseAlphabetical = (a, b) => {
    if (a.data.attributes.address_0 > b.data.attributes.address_0) {
      return -1;
    } else if (a.data.attributes.address_0 < b.data.attributes.address_0) {
      return 1;
    } else {
      return 0;
    }
  };

  const sortDeliveries = (deliveries) => {
    if (sortMode === 0) {
      return deliveries.sort(newToOld);
    } else if (sortMode === 1) {
      return deliveries.sort(oldToNew);
    } else if (sortMode === 2) {
      return deliveries.sort(alphabetical);
    } else if (sortMode === 3) {
      return deliveries.sort(reverseAlphabetical);
    } else {
      return deliveries;
    }
  };

  return (
    <div className="lg:col-span-4 flex flex-col items-stretch">
      <div className="flex flex-row items-center justify-between mb-2 ml-1">
        <div className="text-gray-200 uppercase font-bold select-none">
          <i
            className={`fa fa-${
              stage == "pick" ? "box-alt" : "truck-loading"
            } mr-2 text-gray-200`}
          ></i>
          {stage == "pick" ? "To Pick" : "To Dispatch"}
        </div>
        <div className="flex flex-row items-center mr-2">
          <ModeToggle {...sortModes[sortMode]} onClick={toggleSortMode} />
        </div>
      </div>
      <div
        className="flex flex-col flex-grow overflow-y-auto pr-1 relative"
        style={{
          height: "calc(100vh - 110px)",
          scrollBehavior: "smooth",
        }}
      >
        {sortDeliveries(deliveries).map((delivery, index) => (
          <Delivery
            key={`delivery-${index}`}
            isSelected={
              selectedDelivery?.delivery?.data?.id === delivery.data.id
            }
            selectedBy={selectedDelivery?.selectedBy}
            selectionExists={!!selectedDelivery}
            delivery={delivery}
            index={index}
            onAdvance={() => onAdvance(delivery)}
            onSkip={() => onSkip(delivery)}
            onSelect={() => onSelect(delivery)}
            onMouseEnter={() => onHover(delivery)}
            onMouseLeave={() => onHoverEnd()}
            stage={stage}
          />
        ))}
      </div>
    </div>
  );
};
