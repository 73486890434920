import { Controller } from "stimulus";

export default class extends Controller {
  sendBack(e) {
    let rejectionReason = prompt("Please give a short reason for sending this back, so that the contributor can fix it and resubmit it.", "Not the correct item");
    if (rejectionReason) {
      window.location.replace(`/items/${this.data.get("id")}/reject?reason=${encodeURIComponent(rejectionReason)}`);
    } else {
      window.alert("Please provide a reason for rejecting this item.");
    }
  }
}
