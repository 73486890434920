import React from "react";
import moment from "moment";
import { Badge } from "./subcomponents/Badge";

const calculateNewBadge = (createdAt) => {
  const createdAtDate = Date.parse(createdAt);
  const interval = (Date.now() - createdAtDate) / 1000;
  const twoHoursInSeconds = 60 * 60 * 2;
  if (interval < twoHoursInSeconds) {
    return "New";
  } else {
    return null;
  }
};

const calculateAgingBadge = (createdAt) => {
  const createdAtDate = Date.parse(createdAt);
  const interval = (Date.now() - createdAtDate) / 1000;
  const fourDaysInSeconds = 60 * 60 * 24 * 4;
  if (interval > fourDaysInSeconds) {
    return moment(createdAt).fromNow(true);
  } else {
    return null;
  }
};

export const TagTray = ({ delivery }) => {
  const newBadge = calculateNewBadge(delivery.data.attributes.hansa_created_at);
  const agingBadge = calculateAgingBadge(
    delivery.data.attributes.hansa_created_at
  );
  return (
    <>
      {newBadge && <Badge colour="#2e86de">{newBadge}</Badge>}
      {delivery.data.attributes.is_web && <Badge colour="blue">WEB</Badge>}
      {delivery.data.attributes.order_class === "POS" && (
        <Badge colour="#af37b1">POS</Badge>
      )}
      {delivery.data.attributes.items.some((item) =>
        item.item_description.toLowerCase().includes("next day")
      ) && <Badge colour="red">Next Day</Badge>}
      {delivery.data.attributes.comment && (
        <Badge colour="green">
          <i className="fas fa-comment-lines text-white text-sm"></i>
        </Badge>
      )}
      {agingBadge && <Badge colour="#3F3F3F">{agingBadge} Old</Badge>}
      {delivery.data.attributes.items.some(
        (item) =>
          item.ordered_quantity &&
          item.stocked_quantity &&
          parseInt(item.ordered_quantity) > parseInt(item.stocked_quantity)
      ) && (
        <Badge colour="#DA7707">
          <i className="fad fa-hourglass-half mx-1 text-white"></i>Backorder
        </Badge>
      )}
    </>
  );
};
