import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Delivery } from "./subcomponents/Delivery";
import moment from "moment";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const centralCoords = {
  lat: 51.87262604571618,
  lng: -0.9559999522919128,
};

const RoutePage = ({ deliveries, driverName, routeId, startTime }) => {
  const [currentDeliveries, setCurrentDeliveries] = React.useState(
    deliveries || []
  );
  const [currentStartTime, setCurrentStartTime] = React.useState(
    startTime || "08:00"
  );
  const [map, setMap] = React.useState(null);
  const [maps, setMaps] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [lastDirections, setLastDirections] = React.useState(null);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const movedDeliveries = arrayMove(currentDeliveries, oldIndex, newIndex);
    const clearedArrivals = clearArrivals(movedDeliveries);
    setCurrentDeliveries(clearedArrivals);
  };

  const SortableDelivery = SortableElement(Delivery);

  const SortableList = SortableContainer(({ deliveries }) => {
    return (
      <div
        className="flex flex-col items-stretch overflow-y-auto"
        style={{
          height: "calc(100vh - 200px)",
          scrollBehavior: "smooth",
        }}
      >
        {deliveries.map((delivery, index) => (
          <SortableDelivery
            disabled={index === 0 || index === deliveries.length - 1}
            isStartpoint={index === 0}
            isEndpoint={index === deliveries.length - 1}
            startTime={currentStartTime}
            arrivalTime={
              delivery.arrival && calculateArrivalTime(delivery.arrival)
            }
            hasLunch={currentDeliveries.some((d) => d.lunch)}
            isLunch={delivery.lunch}
            handleSetLunch={() => {
              setCurrentDeliveries(
                clearArrivals(
                  currentDeliveries.map((d) => ({
                    ...d,
                    lunch: d.id === delivery.id,
                  }))
                )
              );
            }}
            handleCancelLunch={() => {
              setCurrentDeliveries(
                clearArrivals(
                  currentDeliveries.map((d) => ({
                    ...d,
                    lunch: false,
                  }))
                )
              );
            }}
            handleDeleteSelf={() => {
              setCurrentDeliveries(
                clearArrivals(
                  currentDeliveries.filter((d) => d.id !== delivery.id)
                )
              );
            }}
            key={`item-${delivery.id}`}
            index={index}
            orderIndex={index}
            {...delivery}
          />
        ))}
      </div>
    );
  });

  const clearArrivals = (deliveries) =>
    deliveries.map(({ arrival, ...delivery }) => ({
      ...delivery,
    }));

  const calculateArrivalTime = (offset) => {
    const parsedStartTime = moment(currentStartTime, "HH:mm");
    const newTime = parsedStartTime.add(offset, "minutes");
    return newTime.format("HH:mm");
  };

  const formatLocation = (delivery) =>
    new maps.LatLng(delivery.lat, delivery.lng);

  useEffect(() => {
    if (!map || !maps) {
      return;
    }

    if (lastDirections) {
      lastDirections.setMap(null);
    }

    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();

    // this "refreshes" the renderer
    directionsDisplay.setMap(map);
    setLastDirections(directionsDisplay);

    directionsService.route(
      {
        origin: { location: formatLocation(currentDeliveries[0]) },
        destination: {
          location: formatLocation(
            currentDeliveries[currentDeliveries.length - 1]
          ),
        },
        waypoints: currentDeliveries.slice(1, -1).map((delivery) => ({
          location: formatLocation(delivery),
          stopover: true,
        })),
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }, [currentDeliveries]);

  const apiIsLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  const performPlan = async (optimize = false) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("X-SECRET-PASSWORD", "Weldmet1983!");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ deliveries: currentDeliveries, optimize });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await (
      await fetch(`/routes/${routeId}/plan`, requestOptions)
    ).json();

    reconcileRoutes(response);
    setLoading(false);
  };

  const performConfirm = async (e) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("X-SECRET-PASSWORD", "Weldmet1983!");
    myHeaders.append("Content-Type", "application/json");

    console.log(currentDeliveries);

    var raw = JSON.stringify({
      deliveries: currentDeliveries,
      startTime: currentStartTime,
      homeInterval: currentDeliveries[currentDeliveries.length - 1].arrival,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await (
      await fetch(`/routes/${routeId}/confirm`, requestOptions)
    ).json();

    const url = response["url"];
    window.location = url;
    setLoading(false);
  };

  const reconcileRoutes = (routes) => {
    const newDeliveries = [];

    routes.forEach((route) => {
      const oldDelivery = currentDeliveries.find((d) => d.id === route.name);
      newDeliveries.push({
        ...oldDelivery,
        arrival: route.arrival,
        distance: route.distance,
      });
    });

    setCurrentDeliveries(newDeliveries);
  };

  return (
    <div className="flex flex-row h-vh items-stretch">
      <div className="w-1/2 p-4 border-r-2">
        <div className="flex flex-row items-center justify-between mb-4">
          <div>
            <div className="text-2xl font-bold">{driverName}</div>
            <div>{currentDeliveries.length - 2} Stops</div>
          </div>
          <div className="flex flex-row items-center">
            <button
              className="green-btn bg-brand-500 text-xl py-4 btn ml-2"
              disabled={loading}
              onClick={() => performPlan(false)}
            >
              <i className="far fa-stopwatch mr-2 -ml-2 text-white"></i>Plan
            </button>
            <button
              className="green-btn bg-orange-600 text-xl py-4 btn ml-2"
              disabled={loading}
              onClick={() => performPlan(true)}
            >
              <i className="far fa-random mr-2 -ml-2 text-white"></i>Optimize
            </button>
            <button
              onClick={performConfirm}
              className="green-btn text-xl py-4 btn ml-2"
              disabled={
                loading ||
                !currentDeliveries
                  .slice(1)
                  .every((delivery) => !!delivery.arrival)
              }
            >
              <i className="far fa-check mr-2 -ml-2 text-white"></i>Confirm
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mb-4">
          <div
            onClick={() => (window.location = `/routes/${routeId}/deliveries`)}
            className="text-blue-600 underline cursor-pointer font-bold"
          >
            Back to picking
          </div>
          <div className="flex flex-row items-center">
            <div className="mr-2 font-bold whitespace-nowrap">Start Time:</div>
            <input
              type="time"
              className="block p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-296 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              value={currentStartTime}
              onChange={(e) => setCurrentStartTime(e.target.value)}
              min="07:00"
              max="18:00"
            />
          </div>
        </div>
        <SortableList deliveries={currentDeliveries} onSortEnd={onSortEnd} />
      </div>
      <div className="w-1/2">
        <div
          style={{
            height: "100%",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCFtkL-zUziK2jgmt902NtO5WcbNSOzsso",
            }}
            defaultCenter={centralCoords}
            defaultZoom={8}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          ></GoogleMapReact>
        </div>
      </div>
    </div>
  );
};

export default RoutePage;
