import React from "react";

export const ModeToggle = ({ icon, label, onClick }) => {
  const [showLabel, setShowLabel] = React.useState(false);
  const [currentTimer, setCurrentTimer] = React.useState(null);

  const toggleMode = () => {
    if (currentTimer) {
      clearTimeout(currentTimer);
      setCurrentTimer(null);
    }
    setShowLabel(true);
    onClick();
    setCurrentTimer(setTimeout(() => setShowLabel(false), 1000));
  };

  return (
    <div className="flex flex-row items-center">
      {showLabel && <div className="mr-2 text-white text-sm">{label}</div>}
      <i
        onClick={toggleMode}
        className={`far fa-${icon} text-lg opacity-80 hover:opacity-100 cursor-pointer text-gray-200`}
      ></i>
    </div>
  );
};
