import React from "react";

export const FeedbackMatrix = ({ feedback, field }) => (
  <ul className="flex flex-col items-start space-y-1 mt-4">
    {feedback
      .filter((x) => x["field"] === field)
      .map((point, pointIndex) => (
        <li key={pointIndex} className="flex items-center">
          <i
            className={`${
              point.right ? "text-green-600 fa-check" : "text-red-600 fa-times"
            } fa mr-2`}
          ></i>
          {point.text}
          {point.fixer && !point.right && (
            <div
              className="ml-2 text-blue-600 underline cursor-pointer"
              onClick={point.fixer}
            >
              Attempt autofix
            </div>
          )}
        </li>
      ))}
  </ul>
);
