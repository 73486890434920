import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const buttons = document.getElementsByClassName("green-btn")
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('mouseup', this.completeAdd, false);
    }
  }

  async completeAdd(event) {
    const button = event.target
    const manufacturer = button.dataset.manufacturer

    let body = {
      manufacturer,
      priority: 0
    }

    await fetch("/manufacturers?redirect=false", {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(body)
    })

    console.log(button)
    button.disabled = true;
  }
}
