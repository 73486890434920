import React from "react";

export const DriverMarker = ({
  text,
  colour,
  machine,
  isSelected,
  selectionExists,
  large = false,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    style={{
      padding: large ? "6px 14px" : "3px 8px 3px 8px",
      width: large ? "38px" : "20px",
      fontSize: large ? "19px" : "12px",
      background: colour,
    }}
    className={`flex flex-row ${
      selectionExists && !isSelected && !large ? "opacity-0" : "opacity-100"
    } items-center shadow justify-center rounded-full select-none`}
  >
    <div className="text-white font-bold">
      {machine ? <i className="fas fa-car-battery text-white"></i> : text[0]}
    </div>
  </div>
);
