import React from "react";
import moment from "moment";

const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const Delivery = ({
  orderIndex,
  address,
  isStartpoint,
  isEndpoint,
  arrivalTime,
  isLunch,
  hasLunch,
  handleSetLunch,
  handleCancelLunch,
  handleDeleteSelf,
}) => {
  const calculateLunchtime = () => {
    if (!arrivalTime) {
      return null;
    }

    const parsedArrivalTime = moment(arrivalTime, "HH:mm");
    const lunchStartTime = parsedArrivalTime.add(12, "minutes");
    const lunchEndTime = lunchStartTime.clone().add(60, "minutes");
    return `${lunchStartTime.format("HH:mm")} to ${lunchEndTime.format(
      "HH:mm"
    )}`;
  };

  return (
    <>
      <div
        className={`rounded ${
          isStartpoint || isEndpoint ? "bg-gray-300" : "bg-white"
        } shadow p-4 mb-2 flex flex-row items-center justify-between select-none`}
      >
        <div className="flex flex-row items-center">
          <div
            className="text-3xl text-white border-2 border-white px-4 py-2 select-none rounded-full shadow"
            style={{ backgroundColor: "#EA4336" }}
          >
            {alphabet[orderIndex].toUpperCase()}
          </div>
          <div className="flex flex-col ml-4 items-stretch">
            <div className="font-bold text-xl">{address[0]}</div>
            {address.slice(1).map((a, i) => (
              <div key={`address-${i}`} className="leading-tight">
                {a}
              </div>
            ))}
          </div>
        </div>
        <div>
          {!isStartpoint && (
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-center justify-center h-full mr-2">
                {!hasLunch && !isEndpoint && !isStartpoint && (
                  <i
                    onMouseDown={handleSetLunch}
                    className="fas fa-utensils-alt text-brand-500 hover:text-brand-800 text-xl cursor-pointer p-3"
                  ></i>
                )}
                {!isEndpoint && !isStartpoint && (
                  <i
                    onMouseDown={handleDeleteSelf}
                    className="fa fa-trash text-2xl text-red-600 hover:text-red-800 cursor-pointer p-3"
                  ></i>
                )}
              </div>
              {!!arrivalTime && (
                <div className="flex flex-col items-end mx-2 text-right">
                  <div className="text-sm">ETA:</div>
                  <div className="font-bold">{arrivalTime}</div>
                  {!isEndpoint && (
                    <>
                      <div className="text-sm mt-3">Time There:</div>
                      <div className="font-bold">12 mins</div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isLunch && (
        <div className="rounded bg-blue-100 shadow p-4 mb-2 flex flex-row items-center justify-between select-none">
          <i className="fas fa-utensils-alt text-brand-800 text-3xl ml-2"></i>
          <div className="flex flex-col items-center text-center text-brand-800">
            <b>Lunchtime</b>
            {arrivalTime ? (
              <div>{calculateLunchtime()}</div>
            ) : (
              <div>1 hour</div>
            )}
          </div>
          <i
            onClick={handleCancelLunch}
            className="fa fa-times text-xl text-brand-800 cursor-pointer p-3"
          ></i>
        </div>
      )}
    </>
  );
};
