import { Controller } from "stimulus";

export default class extends Controller {
  // Targets are the things we want to control from within the JS
  // They will be named with `data-target="..."` attributes in the HTML
  // Stimulus.js (the framework) will automatically create objects for each target
  // on the 'this' keyboard with an appended 'Target' to the name.
  static targets = ["container"]

  addItem() {
    const itemNumber = this.containerTarget.children.length + 1
    // Create a new row
    const newRow = document.createElement("div")
    newRow.className = "flex flex-col md:flex-row md:items-center mb-4 md:mb-0"
    // Create a new item text field
    const newItem = document.createElement("input")
    newItem.className = "large-text-input flex-grow"
    newItem.placeholder = `Item Code #${itemNumber}`
    newItem.setAttribute("data-action", "keydown->labels--index#codeKeyboardPress")
    newItem.name = 'labels[]label[code]'
    // Create a new quantity number field
    const newQuantity = document.createElement("input")
    newQuantity.className = "large-text-input mt-2 md:ml-2 md:mt-0"
    newQuantity.type = "number"
    newQuantity.placeholder = "Quantity"
    newQuantity.value = 1;
    newQuantity.setAttribute("data-action", "keydown->labels--index#keyboardPress")
    newQuantity.name = 'labels[]label[quantity]'
    // Add items
    newRow.appendChild(newItem)
    newRow.appendChild(newQuantity)
    // Append the new item to the end of the container
    this.containerTarget.appendChild(newRow)
    // Focus the keyboard on that text field
    newItem.focus()
  }

  // This is called every time a key is pressed on any of the textboxes
  // We check to see if it's the enter key (which is key code 13)
  // And if it is, we silent the default response (to submit the form)
  // And instead add an item, and focus that field.
  keyboardPress(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.addItem()
    }
  }

  codeKeyboardPress(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      const container = event.target.parentElement;
      const lastChild = container.children[container.children.length - 1];
      lastChild.focus()
    }
  }
}
