import React from "react";

const PointTag = ({ diff, colour }) => (
  <div className={`flex rounded-full px-2 text-white font-bold bg-${colour}`}>
    {diff}
  </div>
);

export const PointBoard = ({ previousFeedback, currentFeedback, field }) => {
  const previousPoints = previousFeedback.filter(
    (x) => x["field"] === field && x["right"]
  ).length;
  const currentPoints = currentFeedback.filter(
    (x) => x["field"] === field && x["right"]
  ).length;

  const diff = currentPoints - previousPoints;

  if (diff > 0) {
    return <PointTag diff={`+${diff}`} colour="green-500" />;
  } else if (diff < 0) {
    return <PointTag diff={`${diff}`} colour="red-500" />;
  } else {
    return <></>;
  }
};
