import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["urlField", "fileField", "urlTab", "fileTab"]
  toggleUrl() {
    this.urlFieldTarget.style.display = "block";
    this.fileFieldTarget.style.display = "none";
    this.urlTabTarget.setAttribute("data-status", "active")
    this.fileTabTarget.setAttribute("data-status", "inactive")
  }
  toggleComputer() {
    this.urlFieldTarget.style.display = "none";
    this.fileFieldTarget.style.display = "block";
    this.urlTabTarget.setAttribute("data-status", "inactive")
    this.fileTabTarget.setAttribute("data-status", "active")
  }
}
