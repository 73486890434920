import React from "react";

export function judgeByWidth(width) {
  if (width < 300) {
    return {
      label: "Poor",
      colour: "red-600",
    };
  } else if (width < 600) {
    return {
      label: "OK",
      colour: "orange-600",
    };
  } else {
    return {
      label: "Good",
      colour: "green-600",
    };
  }
}

export const PhotoRating = ({ width, height }) => {
  const judgement = judgeByWidth(width);
  return (
    <div
      className={`flex rounded-full px-2 text-white font-bold text-sm text-uppercase bg-${judgement["colour"]}`}
    >
      {judgement["label"]}
    </div>
  );
};
