import React from "react";

const descriptions = {
  included: "The following items are supplied within this package.",
  consumables:
    "The following items are consumables or spares for this product.",
  accessories: "The following items are accessories for this product.",
};

export const Subcollections = ({ subcollections, onChange }) => {
  if (subcollections.length === 0)
    return (
      <div className="flex flex-col items-center self-center text-center my-8">
        <div className="text-lg font-semibold">No subcollections yet</div>
        <div>
          Subcollections appear on the product page as links to other associated
          products (e.g., Extras).
        </div>
        <button
          onClick={() =>
            onChange([
              {
                title: "included",
                show_prices: false,
                description: descriptions.included,
                entries: [],
              },
            ])
          }
          className="btn green-btn mt-4"
        >
          Create Subcollection
        </button>
      </div>
    );

  const totalEntries = subcollections.reduce(
    (total, subcollection) => total + subcollection.entries.length,
    0
  );

  const MAX_ENTRIES = 20;

  const canAddMore = totalEntries < MAX_ENTRIES;

  return (
    <>
      <div className="flex flex-col items-stretch">
        {subcollections.map((subcollection, sIndex) => (
          <Subcollection
            key={subcollection.id || sIndex}
            canAddMore={canAddMore}
            subcollection={subcollection}
            subcollectionIndex={sIndex}
            onDelete={() => {
              const newSubcollections = [...subcollections];
              newSubcollections.splice(sIndex, 1);
              onChange(newSubcollections);
            }}
            onChange={(newSubcollection) => {
              const newSubcollections = [...subcollections];
              newSubcollections[sIndex] = newSubcollection;
              onChange(newSubcollections);
            }}
          />
        ))}
      </div>
      <button
        disabled={!canAddMore}
        onClick={() =>
          onChange([
            ...subcollections,
            {
              title: "included",
              show_prices: false,
              description: descriptions.included,
              entries: [],
            },
          ])
        }
        className="btn green-btn mt-4 w-64"
      >
        Add Subcollection
      </button>
    </>
  );
};

const Subcollection = ({
  subcollection,
  canAddMore,
  subcollectionIndex,
  onDelete,
  onChange,
}) => {
  console.log(subcollection);

  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-row items-center justify-between mt-4">
        <select
          className="text-input flex-grow"
          placeholder="Subcollection Title"
          value={subcollection.title}
          onChange={(e) => {
            const otherProps = {
              description: descriptions[e.target.value],
            };
            otherProps.show_prices = e.target.value !== "included";
            onChange({
              ...subcollection,
              ...otherProps,
              title: e.target.value,
            });
          }}
        >
          <option value="included">Included within this package</option>
          <option value="consumables">
            Consumables and spares for this product
          </option>
          <option value="accessories">Accessories for this product</option>
        </select>
        <div className="flex flex-row items-center ml-4">
          <input
            id={`showPrices-${subcollectionIndex}`}
            name={`showPrices-${subcollectionIndex}`}
            type="checkbox"
            className="mr-2 font-bold"
            checked={subcollection.show_prices}
            onChange={(e) => {
              onChange({
                ...subcollection,
                show_prices: !!e.target.checked,
              });
            }}
          />
          <label
            htmlFor={`showPrices-${subcollectionIndex}`}
            className="font-bold"
          >
            Show Prices
          </label>
          <i
            onClick={onDelete}
            className="fa fa-times text-red-600 p-3 px-4 ml-3 hover:bg-red-50 cursor-pointer rounded-md"
          ></i>
        </div>
      </div>
      <textarea
        className="text-input mt-2 w-full"
        placeholder="Subcollection Description"
        rows={2}
        value={subcollection.description}
        onChange={(e) => {
          onChange({ ...subcollection, description: e.target.value });
        }}
      />
      <div className="flex flex-row flex-wrap items-center justify-start mt-2">
        {subcollection.entries.map((entry, entryIndex) => (
          <Entry
            key={entry.id}
            entry={entry}
            onDelete={() => {
              const newEntries = [...subcollection.entries];
              newEntries.splice(entryIndex, 1);
              onChange({ ...subcollection, entries: newEntries });
            }}
            onChange={(newValue) => {
              const newEntries = [...subcollection.entries];
              newEntries[entryIndex] = newValue;
              onChange({ ...subcollection, entries: newEntries });
            }}
          />
        ))}
      </div>
      <button
        disabled={!canAddMore}
        onClick={() => {
          onChange({
            ...subcollection,
            entries: [
              ...subcollection.entries,
              { code: "", id: generateRandomId(), is_link: true, title: "" },
            ],
          });
        }}
        className={`btn green-btn self-center mt-3 ${
          !canAddMore && "opacity-40"
        }`}
      >
        {canAddMore ? "Add Entry" : "Max Entries Reached"}
      </button>
    </div>
  );
};

const Entry = ({ entry, onChange, onDelete }) => {
  const [productTitle, setProductTitle] = React.useState(null);
  const [productImage, setProductImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setProductTitle(null);
    setProductImage(null);

    if (!entry.code) return;
    if (entry.code.length <= 3) return;

    setLoading(true);
    fetch(`/items/${entry.code}/data?key=de2wycwpijfe2uowrgyipqjef`)
      .then((response) => {
        response.json().then((data) => {
          console.log(data);

          if (data.error || !data.item) {
            return;
          }

          if (
            data.item?.shopify_payload &&
            data.item?.shopify_payload?.images
          ) {
            const shopifyPayload = data.item.shopify_payload.images;
            if (shopifyPayload.length > 0) {
              setProductImage(shopifyPayload[0].src);
            }
          }

          setProductTitle(data.item.hansa_description);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entry.code]);

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 m-2 flex flex-col items-center">
      <div className="flex flex-row items-center justify-center w-24 h-24">
        {loading && <div className="font-semibold text-center">Loading...</div>}
        {productImage && (
          <img className="w-full h-full object-contain" src={productImage} />
        )}
      </div>
      {productTitle && (
        <div className="text-center text-sm w-48 my-2">{productTitle}</div>
      )}
      {entry.code && (
        <div className="flex flex-row items-center mb-2">
          <input
            id={`isLink-${entry.id}`}
            name={`isLink-${entry.id}`}
            type="checkbox"
            className="mr-2"
            checked={entry.is_link}
            onChange={(e) => {
              onChange({
                ...entry,
                is_link: !!e.target.checked,
              });
            }}
          />
          <label htmlFor={`isLink-${entry.id}`} className="font-bold">
            Link To Product
          </label>
        </div>
      )}
      {entry.code && (
        <textarea
          value={entry.title}
          className="text-input w-full text-sm flex-grow mb-1"
          rows={3}
          placeholder="Override title (leave blank to use current title)"
          onChange={(e) => {
            onChange({ ...entry, title: e.target.value });
          }}
        />
      )}
      <div className="flex flex-row items-center">
        <input
          value={entry.code}
          className="text-input w-full flex-grow"
          placeholder="Item Code"
          onChange={(e) => {
            onChange({ ...entry, code: e.target.value });
          }}
        />
        <i
          onClick={onDelete}
          className="fa fa-times text-red-600 p-3 px-4 ml-1 hover:bg-red-50 cursor-pointer rounded-md"
        ></i>
      </div>
    </div>
  );
};

function generateRandomId() {
  return Math.random().toString(36).substring(2, 15);
}
