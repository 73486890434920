import React from "react";
import { TagTray } from "../../TagTray";

export const Delivery = ({
  delivery,
  isSelected,
  selectionExists,
  selectedBy,
  stage,
  index,
  onSelect,
  onMouseEnter,
  onMouseLeave,
  onSkip,
  onAdvance,
}) => {
  const fieldRef = React.useRef(null);
  React.useEffect(() => {
    if (isSelected && selectedBy === "key" && fieldRef.current) {
      fieldRef.current.scrollIntoView();
    }
  }, [isSelected, selectedBy]);

  return (
    <div
      className="cursor-pointer pb-1"
      ref={fieldRef}
      key={`delivery-${index}`}
      onClick={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={`${
          selectionExists && !isSelected ? "opacity-60" : "opacity-90"
        } rounded bg-brand-800 border-2 border-${
          isSelected ? "white" : "gray-700"
        } leading-tight hover:border-gray-300 flex flex-row items-center justify-between p-1 shadow-lg ${
          isSelected ? "font-bold" : ""
        }`}
      >
        <span className="mx-2 my-1">
          <span className="text-white select-none">
            {delivery.data.attributes.address_0}
          </span>
          <span className="text-gray-300 ml-2 text-sm">
            {delivery.data.attributes.hansa_id}
          </span>
          <TagTray delivery={delivery} />
        </span>

        <div className="flex flex-row items-center">
          {stage == "pick" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onSkip();
              }}
              className="mr-1 w-8 h-8 cursor-pointer rounded bg-beige border-2 border-brand-500 hover:border-gray-200 hover:shadow flex flex-col items-center justify-center"
            >
              <i className="far fa-fast-forward text-white"></i>
            </div>
          )}

          <div
            onClick={(e) => {
              e.stopPropagation();
              onAdvance();
            }}
            className="w-8 h-8 cursor-pointer rounded bg-beige border-2 border-brand-500 hover:border-gray-200 hover:shadow flex flex-col items-center justify-center"
          >
            <i
              className={`far ${
                stage === "pick" ? "fa-arrow-right" : "fa-check"
              } text-white`}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};
