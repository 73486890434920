import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/solid";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const IssueFlagger = ({ options, onChange }) => {
  return (
    <div className="flex items-center space-x-4">
      {options.filter((opt) => opt.checked).length > 0 && (
        <i className="fa fa-exclamation-triangle text-red-500 text-xl"></i>
      )}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            Flag Issues
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option, optionIndex) => (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        onChange([
                          ...options.slice(0, optionIndex),
                          { ...option, checked: !option.checked },
                          ...options.slice(optionIndex + 1),
                        ]);
                      }}
                      className={classNames(
                        active
                          ? `${
                              option.checked
                                ? "bg-red-800 text-white"
                                : "bg-gray-100 text-gray-900"
                            } `
                          : option.checked
                          ? "text-white"
                          : "text-gray-700",
                        `block px-4 py-2 text-sm ${
                          option.checked
                            ? "bg-red-600 font-bold text-white"
                            : ""
                        }`
                      )}
                    >
                      {option.label}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
