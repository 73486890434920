import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdown"]
  static values = { open: Boolean }

  openMenu(e) {
    if (this.openValue) {
      this.openValue = false;
      this.dropdownTarget.classList.add("hidden")
    } else {
      this.openValue = true;
      this.dropdownTarget.classList.remove("hidden")
    }
  }
}
